export default [
  {
    id: 1,
    to: 'miraisake',
    label: '未来日本酒店 / \n未来酒店'
  },
  {
    id: 2,
    to: 'yummy',
    label: 'YUMMY SAKE'
  },
  {
    id: 4,
    to: 'international',
    label: 'MIRAI SAKE INTERNATIONAL'
  },
  {
    id: 5,
    to: 'brewing',
    label: 'MIRAI SAKE BREWING'
  },
  {
    id: 6,
    to: 'farming',
    label: '酒造好適米生産事業'
  },
  {
    id: 7,
    to: 'keishou',
    label: '未来酒蔵承継機構'
  },
  {
    id: 8,
    to: 'consulting',
    label: 'MIRAI SAKE CONSULTING'
  }
]