import React,{ useMemo } from "react"
import { Link } from 'react-scroll'
import classNames from 'classnames'
import PropTypes from "prop-types"

import styles from '@styles/components/page_nav.module.scss'

import IcnTriangle from '@comp/atom/icn_triangle'

const propTypes = {
  data: PropTypes.array.isRequired,
  wrapClass: PropTypes.string,
  type: PropTypes.string
}

const scrollOption = {
  smooth: true,
  offset: -100,
  isDynamic: true,
  duration: 500
}

const PageNav = ({
  data,
  wrapClass,
  type
}) => {
  const elms = useMemo( () => {
    return data.map((element,index) => {
      return (
        <li key={index} className={styles.itm}>
          <Link
            to={element.to}
            className={styles.btn}
            {...scrollOption}
          >
            <span className={styles.label}>{element.label}</span>
            <span className={styles.icn}><IcnTriangle /></span>
          </Link>
        </li>
      )
    })
  },[data])

  const classNameNav = classNames([
    styles.wrap,
    wrapClass,
    type ? styles.[`is${type}`] : null
  ])
  return (
    <>
      <nav className={classNameNav}>
        <ul className={styles.list}>
          {elms}
        </ul>
      </nav>
    </>
  )
}

PageNav.propTypes = propTypes
export default PageNav