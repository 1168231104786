import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"

import classNames from 'classnames/bind'
import styles from '@styles/pages/project.module.scss'

import Layout from "@comp/layout"
import SEO from '@comp/seo'

import PageNav from '@comp/page_nav'
import PageTitle from '@comp/page_title'

import BtnDetail from '@comp/btn_detail'

import navData from '@const/project_list'

const cx = classNames.bind(styles)

const mvLists = [1,2,3,4]

// markup
const ProjectPage = ({ location }) => {
  const section01Ref = useRef()
  const section02Ref = useRef()
  const section04Ref = useRef()

  const [currentMv, setCurrentMv] = useState(0)

  const handleScroll = useCallback( () => {
    checkContents()
  },[])


  const checkContents = () => {
    const offset = window.innerHeight * 0.5
    const sec01Top = section01Ref.current.getBoundingClientRect().top - offset
    const sec02Top = section02Ref.current.getBoundingClientRect().top - offset
    const sec04Top = section04Ref.current.getBoundingClientRect().top - offset
    
    if(sec04Top < 0) {
      setCurrentMv(3)
    } else if(sec02Top < 0) {
      setCurrentMv(1)
    } else if(sec01Top < 0) {
      setCurrentMv(0)
    }

  }

  const mvElms = useMemo( () => {
    return mvLists.map( (id,i) => {
      const className = cx({
        [`mv__itm${id}`]: true,
        isCurrent: currentMv === i
      })
      return (
        <div key={i} className={className}></div>
      )
    })
  },[currentMv])


  useEffect( () => {
    handleScroll()
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  },[handleScroll])
  return (
    <Layout location={location} pageName="project">
      <SEO
        title={`事業内容`}
        description={`MIRAI SAKE COMPANYは日本酒の生産〜消費者への提案まで、幅広い事業を展開しています。`}
        path={location.pathname}
      />
      <main className={styles.container}>
        <div className={styles.header}>
          <div className={styles.inner}>
            <PageTitle
              label='事業内容'
              labelEn='PROJECT'
            />

            <p className={styles.overview}>
              日本酒の販売から商品企画、生産支援まで、幅広い領域で事業を展開しています。
            </p>
            <PageNav
              data={navData}
            />
          </div>
        </div>
        <div className={styles.contents__top}>
          <div className={styles.contents__top__body}>
            <div className={styles.section__img__sp} role="presentation">
              <img
                src="/images/project/sp/project-mv01.jpg"
                srcSet="
                  /images/project/sp/project-mv01.jpg
                "
                alt=""
              />
            </div>
            <section id="miraisake" ref={section01Ref} className={styles.section_type01}>
              <div className={styles.section_type01__inner}>
                <h1 className={styles.section_type01__title}>
                  <span className={styles.section_type01__title__logo}>
                    <img src="/images/home/logo-miraisake.svg" alt=""/>
                  </span>
                  <span className={styles.section_type01__title__txt}>
                    未来日本酒店 / 未来酒店
                  </span>
                </h1>
                <p className={styles.section_type01__overview}>未来日本酒店のテーマは「知名度やスペックだけじゃない。コンセプトやストーリーで選んだ新しい”SAKEセレクトショップ”」。<br />広く流通する有名銘柄に限らず、味やコンセプトに新規性がありまさに未来の日本を代表していくことになる銘柄や、美味しい一方で地元限定流通であり都心部ではあまり手に入らないものなどを中心に、日本全国から取り寄せた160種類以上もの「未来型」の日本酒を取り揃えています。<br />また、姉妹店の未来酒店ではジャパニーズクラフトビールや、ジャパニーズワインなど日本が誇る幅広い「未来型」の国産酒をお楽しみ頂けます
                </p>
                <ul className={styles.section_type01__images}>
                  <li className={styles.section_type01__img}>
                    <img src="/images/project/miraisake/project-img-miraisake01.jpg" alt=""/>
                    <span className={styles.section_type01__img__caption}>
                      渋谷にて直営店舗を運営しています。
                    </span>
                  </li>
                  <li className={styles.section_type01__img}>
                    <img src="/images/project/miraisake/project-img-miraisake02.jpg" alt=""/>
                    <span className={styles.section_type01__img__caption}>
                      蔵元との直取引を行っているため、珍しい銘柄も多く取り扱っています。
                    </span>
                  </li>
                  <li className={styles.section_type01__img}>
                    <img src="/images/project/miraisake/project-img-miraisake03.jpg" alt=""/>
                    <span className={styles.section_type01__img__caption}>
                      一杯500円〜日本酒を提供。味を確かめてから購入可能です。
                    </span>
                  </li>
                  <li className={styles.section_type01__img}>
                    <img src="/images/project/miraisake/project-img-miraisake04.jpg" alt=""/>
                    <span className={styles.section_type01__img__caption}>
                      オンラインストアでは、遠方のお客様にも未来日本酒店をご利用いただけるよう、各店舗で取り扱っている人気の日本酒を販売しています。
                    </span>
                  </li>
                </ul>
                <ul className={styles.section_type01__links}>
                  <li className={styles.section_type01__link}>
                    <BtnDetail
                      to={'/shop/'}
                    >
                      <span className={styles.section_type01__link__inner}>店舗一覧を見る</span>
                    </BtnDetail>
                  </li>
                  <li className={styles.section_type01__link}>
                    <BtnDetail
                      href={'https://shop.miraisake.com/'}
                      target={'_blank'}
                    >
                      <span className={styles.section_type01__link__inner}>オンラインストアを見る</span>
                    </BtnDetail>
                  </li>
                </ul>
              </div>
            </section>
            <div className={styles.section__img__sp} role="presentation">
              <img
                src="/images/project/sp/project-mv02.jpg"
                srcSet="
                  /images/project/sp/project-mv02.jpg
                "
                alt=""
              />
            </div>
            <section id="yummy" ref={section02Ref} className={styles.section_type01}>
              <div className={styles.section_type01__inner}>
                <h1 className={styles.section_type01__title}>
                  <span className={styles.section_type01__title__logo}>
                    <img src="/images/home/logo-yummy.svg" alt=""/>
                  </span>
                  <span className={styles.section_type01__title__txt}>
                    YUMMY SAKE
                  </span>
                </h1>
                <p className={styles.section_type01__overview}>YUMMY SAKEは、人工知能とブラインドテイスティングによって「自分の味覚」と出会える日本酒テイスティング・エンタテインメントです。YUMMY SAKEをバーやショップで一度体験すれば、難しい用語や専門的なルールを知らなくても、誰でも直感的に日本酒を選べるようになります。</p>
                <ul className={styles.section_type01__images}>
                  <li className={styles.section_type01__img}>
                    <img src="/images/project/yummy/project-img-yummy01.jpg" alt=""/>
                    <span className={styles.section_type01__img__caption}>
                      10種の日本酒を飲み比べ、スマートフォンに感想を入力します。
                    </span>
                  </li>
                  <li className={styles.section_type01__img}>
                    <img src="/images/project/yummy/project-img-yummy02.jpg" alt=""/>
                    <span className={styles.section_type01__img__caption}>
                      自分の味覚タイプが12種から判定されます。
                    </span>
                  </li>
                  <li className={styles.section_type01__img}>
                    <img src="/images/project/yummy/project-img-yummy03.jpg" alt=""/>
                    <span className={styles.section_type01__img__caption}>
                      提携する酒販店や飲食店ではYummyTypeで自分に合うお酒を選ぶことができます
                    </span>
                  </li>
                  <li className={styles.section_type01__img}>
                    <img src="/images/project/yummy/project-img-yummy04.jpg" alt=""/>
                    <span className={styles.section_type01__img__caption}>
                      1600蔵、20000以上からなる日本酒ジャンルの中から、あなたに最も合う商品を提案します。
                    </span>
                  </li>
                </ul>
                <ul className={styles.section_type01__links}>
                  <li className={styles.section_type01__link}>
                    <BtnDetail
                      href={'https://yummysake.jp/'}
                      target={'_blank'}
                    >
                      <span className={styles.section_type01__link__inner}>ブランドサイトを見る</span>
                    </BtnDetail>
                  </li>
                </ul>
              </div>
            </section>
            <div className={styles.section__img__sp} role="presentation">
              <img
                src="/images/project/sp/project-mv03.jpg"
                srcSet="
                  /images/project/sp/project-mv03.jpg
                "
                alt=""
              />
          </div>
          <div className={styles.section__img__sp} role="presentation">
            <img
              src="/images/project/sp/project-mv04.jpg"
              srcSet="
                /images/project/sp/project-mv04.jpg
              "
              alt=""
            />
          </div>
            <section id="international" ref={section04Ref} className={styles.section_type01}>
              <div className={styles.section_type01__inner}>
                <h1 className={styles.section_type01__title}>
                  <span className={styles.section_type01__title__logo}>
                    <img src="/images/home/logo-international.svg" alt=""/>
                  </span>
                  <span className={styles.section_type01__title__txt}>
                    MIRAI SAKE INTERNATIONAL
                  </span>
                </h1>
                <p className={styles.section_type01__overview}>MIRAI SAKE INTERNATIONALは、英国ロンドンを拠点とするMIRAI SAKE COMPANYの海外事業統括会社です。現在急成長している海外市場に向けて日本酒文化の発信拠点を持ち、新規顧客開拓、顧客ニーズの創出、日本酒ブランディングを行なっていきます。</p>
                <ul className={styles.section_type01__images}>
                  <li className={styles.section_type01__img}>
                    <img src="/images/project/international/project-img-international01.jpg" alt=""/>
                    <span className={styles.section_type01__img__caption}>
                      イギリスはヨーロッパで最大の日本酒/SAKEの消費地です。
                    </span>
                  </li>
                  <li className={styles.section_type01__img}>
                    <img src="/images/project/international/project-img-international02.jpg" alt=""/>
                    <span className={styles.section_type01__img__caption}>
                      ロンドンで最も発展するShorditch/Oldstreetエリアで欧州最大級のSakeセレクトショップSake Collectiveを運営しています。
                    </span>
                  </li>
                  <li className={styles.section_type01__img}>
                    <img src="/images/project/international/project-img-international03.jpg" alt=""/>
                    <span className={styles.section_type01__img__caption}>
                      シンガポールでも今後事業開始を予定しています。
                    </span>
                  </li>
                  <li className={styles.section_type01__img}>
                    <img src="/images/project/international/project-img-international04.jpg" alt=""/>
                    <span className={styles.section_type01__img__caption}>
                      YUMMY SAKEの英語版も運用しています。
                    </span>
                  </li>
                </ul>
              </div>
            </section>
          </div>
          <div className={styles.mv}>{ mvElms }</div>
        </div>

        <div className={styles.contents__bottom}>
          <div className={styles.inner}>
            <section id="brewing" className={styles.section_type02}>
              <div className={styles.section_type02__inner}>
                <h1 className={styles.section_type02__title}>
                  <span className={styles.section_type02__title__logo}>
                    <img src="/images/home/logo-brewing.svg" alt=""/>
                  </span>
                  <span className={styles.section_type02__title__txt}>
                    MIRAI SAKE BREWING
                  </span>
                </h1>
                <div className={styles.section_type02__img}>
                  <img src="/images/project/project-img-brewing01.jpg" alt=""/>
                </div>
                <p className={styles.section_type02__overview}>
                  SAKE の新しい価値を求め、酒質と世界観をデザインしたオリジナルブランド。未来日本酒店を中心に20 銘柄以上を企画・設計しています。
                </p>
              </div>
            </section>

            <section id="farming" className={styles.section_type02}>
              <div className={styles.section_type02__inner}>
                <h1 className={styles.section_type02__title}>
                  <span className={styles.section_type02__title__logo}>
                    <img src="/images/home/logo-farming.svg" alt=""/>
                  </span>
                  <span className={styles.section_type02__title__txt}>
                    酒造好適米生産事業
                  </span>
                </h1>
                <div className={styles.section_type02__img}>
                  <img src="/images/project/project-img-farming01.jpg" alt=""/>
                </div>
                <p className={styles.section_type02__overview}>
                  テロワールを重視するMIRAI SAKE COMPANYが理想のSAKEを米から実現しようと2018年からスタートした事業です。現在は佐渡島で五百万石の生産を実施しています。
                </p>
              </div>
            </section>

            <section id="keishou" className={styles.section_type02}>
              <div className={styles.section_type02__inner}>
                <h1 className={styles.section_type02__title}>
                  <span className={styles.section_type02__title__logo}>
                    <img src="/images/home/logo-keishou.svg" alt=""/>
                  </span>
                  <span className={styles.section_type02__title__txt}>
                    未来酒蔵承継機構
                  </span>
                </h1>
                <div className={styles.section_type02__img}>
                  <img src="/images/project/project-img-keishou01.jpg" alt=""/>
                </div>
                <p className={styles.section_type02__overview}>
                  後継者不足や資本不足に悩む酒蔵のオーナー様と、事業の承継により日本酒文化の新たな担い手となることを希望するスポンサー様を繋ぐ組織です。酒造業の廃業をゼロにし、新たな日本酒産業の担い手を増やすことを目的とします。
                </p>
              </div>
            </section>

            <section id="consulting" className={styles.section_type02}>
              <div className={styles.section_type02__inner}>
                <h1 className={styles.section_type02__title}>
                  <span className={styles.section_type02__title__logo}>
                    <img src="/images/home/logo-consulting.svg" alt=""/>
                  </span>
                  <span className={styles.section_type02__title__txt}>
                    MIRAI SAKE CONSULTING
                  </span>
                </h1>
                <div className={styles.section_type02__img}>
                  <img src="/images/project/project-img-consulting01.jpg" alt=""/>
                </div>
                <p className={styles.section_type02__overview}>
                  広範に渡る事業経験を元に、日本酒産業に関する最先端の知見を業界内外の法人様や自治体様に還元します。グローバルトップ企業に在籍した複数のメンバーが、経営戦略コンサルティングや財務コンサルティングを中心としたハイクオリティのサービスをリーズナブルな価格でご提供します。
                </p>
              </div>
            </section>
          </div>          
        </div>
      </main>
    </Layout>
  )
}

export default ProjectPage
